import clsx from "clsx"

import { Stack, type StackProps } from "~/components/abstracts/Stack"

import { sprinkles, type Sprinkles } from "~/styles/sprinkles.css"

export type SliderBulletsProps = {
  currentIndex?: number
  totalItems?: number
  size?: number
  bulletClassName?: string
  activeColor?: Sprinkles["bgColor"]
  bulletSprinkles?: Omit<Sprinkles, "bgColor">
} & StackProps

function SliderBullets({
  currentIndex = 0,
  totalItems = 0,
  size = 6,
  bulletClassName,
  activeColor = "blue-deep",
  bulletSprinkles,
  gap = 5,
  ...props
}: SliderBulletsProps) {
  return (
    <Stack gap={gap} {...props}>
      {[...Array(totalItems).keys()]?.map((itemIndex) => {
        const isActive = currentIndex === itemIndex

        return (
          <i
            style={{ height: size, width: size }}
            className={clsx(
              bulletClassName,
              sprinkles({
                bgColor: isActive ? activeColor : "grey-border",
                borderRadius: "rounded",
                ...bulletSprinkles,
              })
            )}
            key={`bullet_${itemIndex}`}
          />
        )
      })}
    </Stack>
  )
}

export { SliderBullets }
