"use client"

import clsx from "clsx"

import Slider, { useSliderState } from "@unlikelystudio/react-slider"

import type { PropsWithClassName } from "~/@types/generics"
import { useLocale } from "~/lib/i18n/hooks/useLocale"
import { Image, type Sizes } from "~/components/ui/Image"
import { Link } from "~/components/ui/Link"
import type { TProductCard } from "~/components/ui/Product/ProductCard/_data/serializer"
import type { SizesStyle } from "~/components/ui/Product/ProductCard/types"
import { ProductTag } from "~/components/ui/Product/ProductTag"
import { SliderBullets } from "~/components/ui/Slider/SliderBullets"
import { serializeViewedItemListPayload, type ViewedItemListPayload } from "~/providers/GTMTrackingProvider/constants"
import { useTracking } from "~/providers/GTMTrackingProvider/hooks/use-tracking"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import { sprinkles } from "~/styles/sprinkles.css"
import { hideFrom, showFrom } from "~/styles/utils/show-hide"

import * as css from "./styles.css"

//replace by the InferReturn typeof zod schema when serialized
export type ProductCardProps = PropsWithClassName<{
  product: TProductCard
  sizesFromBreakpoint: Sizes
  sliderItemClassName?: string
  layout?: keyof SizesStyle
  priority?: boolean
  mobileSliderDisplay?: boolean
  trackingData: Pick<ViewedItemListPayload, "item_list_name" | "index">
}>

function ProductCard({
  className,
  product,
  sliderItemClassName,
  sizesFromBreakpoint,
  priority = false,
  layout = "large",
  mobileSliderDisplay = true,
  trackingData,
}: ProductCardProps) {
  const t = useTranslate()
  const { sendEvent, sendResetEvent } = useTracking()

  const locale = useLocale()
  const hoverImage = product.images?.[1]
  const firstImage = product.images?.[0]

  const [{ slideIndex }, setSliderState] = useSliderState()

  const productPriceWithoutReduction = product?.compareAtPrice ?? null

  const maxItemsSlider = 2
  const productImages = product.images.slice(0, maxItemsSlider)

  return (
    <Link
      className={clsx(className, css.ProductCard)}
      {...product.link}
      data-comp="UI/Product/ProductCard"
      onClick={() => {
        sendResetEvent()
        sendEvent("select_item_list", {
          ecommerce: { items: [serializeViewedItemListPayload(product, product.collection, trackingData, locale)] },
        })
      }}
    >
      <div
        className={clsx(css.imageContainer, mobileSliderDisplay ? showFrom("l", "block") : showFrom("mobile", "block"))}
      >
        <div className={clsx(css.imageHover)}>
          {hoverImage && (
            <Image
              asPlaceholder
              ratio="6/7"
              sizesFromBreakpoints={sizesFromBreakpoint}
              objectFit={({ isPNG }) => (isPNG ? "contain" : "cover")}
              {...hoverImage}
            />
          )}
        </div>
        {firstImage && (
          <Image
            asPlaceholder
            ratio="6/7"
            sizesFromBreakpoints={sizesFromBreakpoint}
            objectFit={({ isPNG }) => (isPNG ? "contain" : "cover")}
            {...firstImage}
            priority={priority}
          />
        )}
      </div>

      <div
        className={clsx(
          sprinkles({ position: "relative" }),
          mobileSliderDisplay ? hideFrom("l", "block") : hideFrom("mobile", "block")
        )}
      >
        <Slider setSliderState={setSliderState} maxSlideIndexChange={1} snap autoBlockSlideIndexChange>
          {productImages.map((image, i) => (
            <Image
              asPlaceholder
              className={sliderItemClassName}
              objectFit={({ isPNG }) => (isPNG ? "contain" : "cover")}
              key={`product-card-${i}`}
              ratio="6/7"
              sizesFromBreakpoints={sizesFromBreakpoint}
              priority={i === 0 ? priority : false}
              {...image}
            />
          ))}
        </Slider>
        <SliderBullets
          sprinklesCss={{ position: "absolute" }}
          className={clsx(css.SliderBullets, css.SliderBulletsVariants[layout])}
          currentIndex={slideIndex}
          totalItems={productImages.length}
          size={4}
        />
      </div>

      {product.tag && (
        <div className={css.TagWrapper}>
          <ProductTag>{product.tag}</ProductTag>
        </div>
      )}
      <div className={clsx(css.head[layout])}>
        <div className={css.firstCol[layout]}>
          {product.title && <div className={clsx(css.titleText[layout])}>{product.title}</div>}
          {product.subtitle && <div className={css.subtitleText[layout]}>{product.subtitle}</div>}
        </div>
        <div className={css.secondCol[layout]}>
          {product.colorsLength > 0 ? (
            <div className={clsx(css.colorsText[layout])}>
              {t(product.colorsLength === 1 ? "product_card_color" : "product_card_colors", {
                color_number: product.colorsLength,
              })}
            </div>
          ) : null}
          {product.price && (
            <div className={css.priceText[layout]}>
              {product.hasMultiplePrices && <> {t("product_price_from")} </>}
              {productPriceWithoutReduction && (
                <>
                  <span className={css.productPriceWithoutReduction}>{productPriceWithoutReduction}</span>{" "}
                </>
              )}
              <span
                className={clsx({
                  [css.priceBold]: Boolean(productPriceWithoutReduction),
                })}
              >
                {product.price}
              </span>
            </div>
          )}
        </div>
      </div>
    </Link>
  )
}

export default ProductCard
