import type { ComponentProps } from "react"
import clsx from "clsx"

import { sprinkles } from "~/styles/sprinkles.css"
import { text } from "~/styles/variants"

type ProductTagProps = ComponentProps<"span">

function ProductTag({ className, children }: ProductTagProps) {
  return (
    <span
      className={clsx(
        className,
        sprinkles({ display: "inline-flex", p: 5, pB: 4, bgColor: "white", textAlign: "center" }),
        text({ design: "spezia-13", transform: "uppercase", lineHeight: 1, letterSpacing: 0.4, color: "blue-deep" })
      )}
      data-comp="UI/Product/ProductTag"
    >
      {children}
    </span>
  )
}

export { ProductTag, type ProductTagProps }
